/* Modules */
export const MODULE_CORE = "CORE";
export const MODULE_ORGANIZATION = "ORGANIZATION";
export const MODULE_RSM = "RSM";
export const MODULE_IOR = "IOR";
export const MODULE_DOM = "DOM";
export const MODULE_QAM = "QAM";
export const MODULE_TRG = "TRAINING";
export const MODULE_ADM = "ADM";

/* Settings permissions */
export const PERMISSION_MANAGE_CORE_SETTINGS = "manage-core-settings";
export const PERMISSION_MANAGE_QAM_SETTINGS = "manage-qam-settings";
export const PERMISSION_MANAGE_QAM_CLASSIFICATIONS = "manage-qam-classifications";
export const PERMISSION_VIEW_QAM_SETTINGS = "view-qam-settings";
export const PERMISSION_VIEW_ORG_SETTINGS = "view-org-settings";
export const PERMISSION_MANAGE_ORG_SETTINGS = "manage-org-settings";
export const PERMISSION_MANAGE_TRG_SETTINGS = "manage-trg-settings";
export const PERMISSION_VIEW_TRG_SETTINGS = "view-trg-settings";
export const PERMISSION_VIEW_RSM_SETTINGS = "view-rsm-settings";
export const PERMISSION_MANAGE_RSM_SETTINGS = "manage-rsm-settings";
export const PERMISSION_VIEW_IOR_SETTINGS = "view-ior-settings";
export const PERMISSION_MANAGE_IOR_SETTINGS = "manage-ior-settings";
export const PERMISSION_VIEW_DOM_SETTINGS = "view-dom-settings";
export const PERMISSION_MANAGE_DOM_SETTINGS = "manage-dom-settings";
export const PERMISSION_MANAGE_DOM_HEADERS_FOOTERS_COVERS = "manage-dom-headers-footers-covers";

/* ORG permissions */
export const PERMISSION_VIEW_ORG_CHART = "view-organizational-chart";
export const PERMISSION_VIEW_PERSONAL_STAFF_INFORMATION = "view-personal-staff-information";
export const PERMISSION_VIEW_ORG_STAFF = "view-staff";
export const PERMISSION_MANAGE_ORG_STAFF = "manage-staff";
export const PERMISSION_EXPORT_ORG_STAFF = "export-staff";

/* Core permissions */
export const PERMISSION_VIEW_CORE_USERS = "view-users";
export const PERMISSION_MANAGE_CORE_USERS = "manage-users";
export const PERMISSION_EXPORT_CORE_USERS = "export-users";
export const PERMISSION_VIEW_CORE_GROUPS = "view-groups";
export const PERMISSION_MANAGE_CORE_GROUPS = "manage-groups";
export const PERMISSION_VIEW_CORE_NOTIFICATIONS = "view-core-notifications";
export const PERMISSION_VIEW_CORE_AUDIT_LOG = "view-core-audit-log";

/* DOM permissions */
export const PERMISSION_VIEW_DOM_DASHBOARD = "view-dom-dashboard";
export const PERMISSION_VIEW_DOM_DOCUMENTS = "view-dom-documents";
export const PERMISSION_CREATE_DOM_DOCUMENTS = "create-dom-documents";
export const PERMISSION_MANAGE_DOM_ALL_DOCUMENTS = "manage-all-dom-documents";
export const PERMISSION_MANAGE_DOM_DOCUMENTS = "manage-dom-documents";
export const PERMISSION_EXPORT_DOM_DOCUMENTS = "export-dom-documents";
export const PERMISSION_VIEW_DOM_ENTITIES = "view-dom-entities";
export const PERMISSION_MANAGE_DOM_ENTITIES = "manage-dom-entities";
export const PERMISSION_EXPORT_DOM_ENTITIES = "export-dom-entities";
export const PERMISSION_VIEW_DOM_RESPONSIBLE_PERSONS = "view-dom-responsible-persons";
export const PERMISSION_MANAGE_DOM_RESPONSIBLE_PERSONS = "manage-dom-responsible-persons";
export const PERMISSION_EXPORT_DOM_RESPONSIBLE_PERSONS = "export-dom-responsible-persons";
export const PERMISSION_VIEW_DOM_APPROVERS = "view-dom-approvers";
export const PERMISSION_MANAGE_DOM_APPROVERS = "manage-dom-approvers";
export const PERMISSION_EXPORT_DOM_APPROVERS = "export-dom-approvers";
export const PERMISSION_VIEW_DOM_TEMPLATES = "view-dom-templates";
export const PERMISSION_MANAGE_DOM_TEMPLATES = "manage-dom-templates";
export const PERMISSION_EXPORT_DOM_TEMPLATES = "export-dom-templates";

/* QAM permissions */
export const PERMISSION_VIEW_QAM_DASHBOARD = "view-qam-dashboard";
export const PERMISSION_VIEW_QAM_AUDITS = "view-qam-audits";
export const PERMISSION_EXPORT_QAM_AUDITS = "export-qam-audits";
export const PERMISSION_VIEW_QAM_ENTITIES = "view-qam-entities";
export const PERMISSION_VIEW_QAM_AUDITORS = "view-qam-auditors";
export const PERMISSION_VIEW_QAM_AUDITEES = "view-qam-auditees";
export const PERMISSION_VIEW_QAM_NORMS = "view-qam-norms";
export const PERMISSION_EXPORT_QAM_NORMS = "export-qam-norms";
export const PERMISSION_MANAGE_QAM_NORMS = "manage-qam-norms";
export const PERMISSION_VIEW_QAM_CORRECTIVE_ACTIONS = "view-qam-corrective-actions";
export const PERMISSION_MANAGE_QAM_CORRECTIVE_ACTIONS = "manage-qam-corrective-actions";
export const PERMISSION_EXPORT_QAM_CORRECTIVE_ACTIONS = "export-qam-corrective-actions";
export const PERMISSION_MANAGE_QAM_CORRECTIVE_ACTIONS_CONTENT = "manage-qam-corrective-action-content";
export const PERMISSION_ALLOW_QAM_CORRECTIVE_ACTION_ADDING_AS_AUDITEE = "allow-qam-corrective-action-adding-as-auditee";
export const PERMISSION_VIEW_QAM_ROOT_CAUSES = "view-qam-root-causes";
export const PERMISSION_MANAGE_QAM_ENTITIES = "manage-qam-entities";
export const PERMISSION_EXPORT_QAM_ENTITIES = "export-qam-entities";
export const PERMISSION_MANAGE_QAM_AUDITORS = "manage-qam-auditors";
export const PERMISSION_EXPORT_QAM_AUDITORS = "export-qam-auditors";
export const PERMISSION_MANAGE_QAM_AUDITEES = "manage-qam-auditees";
export const PERMISSION_EXPORT_QAM_AUDITEES = "export-qam-auditees";
export const PERMISSION_MANAGE_QAM_AUDITS = "manage-qam-audits";
export const PERMISSION_MANAGE_QAM_ALL_AUDITS = "manage-all-qam-audits";
export const PERMISSION_CREATE_QAM_AUDITS = "create-qam-audits";
export const PERMISSION_MANAGE_QAM_ROOT_CAUSES = "manage-qam-root-causes";
export const PERMISSION_ASSIGN_QAM_AUDITS = "assign-qam-audits";

/* TRG permissions */
export const PERMISSION_MANAGE_TRG = "manage-trg";
export const PERMISSION_VIEW_TRG_PLANNING = "view-planning";
export const PERMISSION_MANAGE_TRG_PLANNING = "manage-planning";

/* ADM permissions */
export const PERMISSION_VIEW_ADM = "view-adm";
export const PERMISSION_MANAGE_ADM = "manage-adm";

/* RSM permissions */
export const PERMISSION_MANAGE_RSM_PUBLICATIONS = "manage-publications";
export const PERMISSION_VIEW_RSM_PUBLICATIONS = "view-publications";
export const PERMISSION_VIEW_RSM_REPORTING = "view-rsm-reporting";

/* IOR permissions */
export const PERMISSION_VIEW_IOR_REPORTS = "view-ior-report";
export const PERMISSION_MANAGE_IOR_REPORTS = "manage-ior-report";
export const PERMISSION_CREATE_IOR_REPORTS = "create-ior-report";
export const PERMISSION_VIEW_IOR_ALL_REPORTS = "view-ior-all-reports";
export const PERMISSION_VIEW_IOR_ROOT_CAUSES = "view-ior-root-causes";
export const PERMISSION_MANAGE_IOR_ROOT_CAUSES = "manage-ior-root-causes";
export const PERMISSION_VIEW_IOR_CORRECTIVE_ACTIONS = "view-ior-corrective-actions";
export const PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS = "manage-ior-corrective-actions";
export const PERMISSION_MANAGE_IOR_CORRECTIVE_ACTIONS_CONTENT = "manage-ior-corrective-action-content";
export const PERMISSION_EXPORT_IOR_CORRECTIVE_ACTIONS = "export-ior-corrective-actions";

export const PERMISSION_VIEW_IOR_ASSIGNEES = "view-ior-assignees";
export const PERMISSION_MANAGE_IOR_ASSIGNEES = "manage-ior-assignees";
export const PERMISSION_EXPORT_IOR_ASSIGNEES = "export-ior-assignees";
export const PERMISSION_VIEW_IOR_INVESTIGATORS = "view-ior-investigators";
export const PERMISSION_MANAGE_IOR_INVESTIGATORS = "manage-ior-investigators";
export const PERMISSION_EXPORT_IOR_INVESTIGATORS = "export-ior-investigators";
